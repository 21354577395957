<ng-container *ngIf="{ comego: times$listView | async } as views">
  <ng-container
    *ngTemplateOutlet="views.comego ? itemListView : itemListNoItems; context: { $implicit: views.comego }"
  ></ng-container>
</ng-container>
<ng-template #itemListNoItems>
  <div class="pos-relative group-wrap rounded-inherit flex h-40 flex-col items-center justify-center space-y-4">
    <p translate>utils.noitems</p>
  </div>
</ng-template>
<ng-template #itemListView let-list>
  <div class="pos-relative group-wrap rounded-inherit flex flex-col space-y-4">
    <ng-container *ngFor="let view of list; let lastList = last; let firstList = first; trackBy: trackId">
      <mat-card class="pos-relative group-wrap rounded">
        <mat-card-title-group class="group-header flex flex-wrap">
          <mat-card-title class="group-date">{{ view.date | dfnsFormat: 'iiii' }}</mat-card-title>
          <mat-card-subtitle class="group-date-full">{{ view.date | formatDate: 'PP' }}</mat-card-subtitle>
          <div class="flex-auto"></div>
          <div class="ml-auto flex flex-col items-end space-y-1 lg:flex-row lg:items-center lg:space-x-3 lg:space-y-0">
            <ng-container *ngIf="view.meta.workinghoursErrors as werrs">
              <div
                class="flex h-10 w-10 items-center justify-center"
                matTooltip
                [matTooltipTemplate]="whErrorTooltip"
                [matTooltipTemplateContext]="{ errors: werrs }"
              >
                <mat-icon color="accent">warning</mat-icon>
              </div>
            </ng-container>
            <ng-container *ngIf="view.schedule as sched">
              <div class="mat-bd-5 flex h-12 flex-shrink-0 items-center rounded-lg px-4">
                <div class="flex flex-col space-y-0.5">
                  <div class="mat-hint text-sm leading-none" translate>comego.types.work</div>
                  <div class="text-md font-semibold leading-none">{{ sched.usage | parseMsAsDuration }}</div>
                </div>
                <div class="mx-3">
                  <mat-divider inset class="h-8 rotate-6" vertical></mat-divider>
                </div>
                <div class="flex flex-col space-y-0.5">
                  <ng-container *ngIf="view.isPending; else plannedView">
                    <div class="mat-hint text-sm leading-none" translate>schedules.dashboard.future</div>
                    <div
                      class="text-md font-semibold leading-none"
                      [ngClass]="sched.pending > 0.0 && 'mat-text-primary'"
                      *ngIf="sched.pending >= 0; else pendingOvertimeText"
                    >
                      {{ sched.pending | parseMsAsDuration }}
                    </div>
                    <ng-template #pendingOvertimeText>
                      <div class="text-success text-md font-semibold leading-none">
                        +{{ sched.pending | parseMsAsDuration }}
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-template #plannedView>
                    <ng-container *ngIf="{ value: sched.usage - sched.cap } as diff">
                      <div class="mat-hint text-sm leading-none" translate>schedules.dashboard.difference</div>
                      <div
                        class="text-md font-semibold leading-none"
                        [ngClass]="diff.value < 0 ? 'text-warn' : diff.value > 0 ? 'text-success' : null"
                      >
                        {{ diff.value < 0 ? '-' : diff.value > 0 ? '+' : null }}{{ diff.value | parseMsAsDuration }}
                      </div>
                    </ng-container>
                  </ng-template>
                </div>
                <div class="mx-2"></div>
                <div class="mat-circle-card -mr-1">
                  <mat-progress-spinner
                    [value]="sched.percent * 100 | clamp: 0 : 100"
                    strokeWidth="3"
                    [diameter]="20"
                    [color]="sched.graphState"
                    class="with-back-spinner"
                  ></mat-progress-spinner>
                </div>
              </div>
            </ng-container>
            <div class="mat-bd-5 flex h-12 flex-shrink-0 items-center rounded-lg px-4">
              <div class="flex flex-col space-y-0.5">
                <div class="mat-hint text-sm leading-none" translate>comego.types.pause_short</div>
                <div class="text-md font-semibold leading-none">{{ view.sum.pause | parseMsAsDuration }}</div>
              </div>
              <ng-container *ngIf="view.sum.absence as absence">
                <div class="mx-3">
                  <mat-divider inset class="h-8 rotate-6" vertical></mat-divider>
                </div>
                <div class="flex flex-col space-y-0.5">
                  <div class="mat-hint text-sm leading-none" translate>comego.types.absence</div>
                  <div class="text-md font-semibold leading-none">{{ absence | parseMsAsDuration }}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-card-title-group>
        <mat-card-content>
          <ng-container *ngFor="let item of view.times; let last = last; let first = first; trackBy: trackId">
            <div class="relative flex items-start space-x-2">
              <ng-container
                *ngTemplateOutlet="
                  dotView;
                  context: {
                    $implicit: item,
                    last,
                    first,
                    isBetween: !last && !first,
                  }
                "
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="
                  itemView;
                  context: {
                    $implicit: item,
                    editable: view.editable && !item.meta.recording,
                    deleteable: view.deleteable,
                    view: view,
                    last,
                    first,
                    isBetween: !last && !first,
                    meta: item.meta,
                  }
                "
              ></ng-container>
            </div>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
</ng-template>
<ng-template #eventView let-events>
  <div class="relative flex flex-col space-y-2 pt-2">
    <ng-container *ngFor="let item of events; let last = last; let first = first; trackBy: trackId">
      <div class="flex items-start space-x-2">
        <div
          class="bg-primary-8 z-[2] mt-0.5 flex size-8 items-center justify-center rounded-full p-2 leading-8 text-white"
        >
          <mat-icon inline class="text-center">access_time</mat-icon>
        </div>
        <ng-container
          *ngTemplateOutlet="
            itemView;
            context: {
              $implicit: item,
              last,
              first,
              isBetween: !last && !first,
              meta: item.meta,
            }
          "
        ></ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #dotView let-item let-between="isBetween" let-first="first" let-last="last" let-line="line">
  <div class="flex size-12 flex-shrink-0 items-center justify-center" fxHide.lt-sm>
    <div
      class="absolute bottom-[20px] z-[1] flex h-full w-10 items-start justify-center"
      *ngIf="line === 'top' || !first"
    >
      <div class="h-full w-[2px] bg-gray-300 dark:bg-[#3E3E3E]"></div>
    </div>
    <div
      *ngIf="!line && item.meta"
      class="z-[2] flex items-center justify-center rounded-full text-neutral-600 dark:text-neutral-100"
      [ngClass]="
        item.type === 'work'
          ? 'size-8 bg-neutral-200 p-2 leading-8 dark:border dark:border-green-400 dark:bg-green-600/60 dark:text-green-200'
          : item.type === 'absence'
            ? 'size-8 bg-neutral-200 p-2 leading-8 dark:border dark:border-violet-400 dark:bg-violet-800/60 dark:text-violet-100'
            : 'size-6 bg-neutral-200 p-0 leading-6 dark:bg-neutral-600'
      "
    >
      <mat-icon inline class="text-center">{{ calcType(iconMap[item.meta.type]) }}</mat-icon>
    </div>
    <div
      class="absolute top-[20px] z-[1] flex h-full w-10 items-start justify-center"
      *ngIf="line === 'bottom' || !last"
    >
      <div class="h-full w-[2px] bg-gray-300 dark:bg-[#3E3E3E]"></div>
    </div>
  </div>
</ng-template>
<ng-template #itemView let-item let-view="view" let-editable="editable" let-deleteable="deleteable" let-meta="meta">
  <div class="group flex flex-shrink-0 items-center justify-between space-x-6" ngClass.lt-sm="flex-grow">
    <div
      (click)="editable && editItem(view, item)"
      class="flex flex-col space-y-2 rounded-lg px-2 py-1.5 lg:flex-row lg:items-center lg:justify-between lg:space-x-6 lg:space-y-0 lg:wflex-[0_0_480px]"
      [ngClass]="{ 'group-hover:mat-bg-5 cursor-pointer': editable }"
      ngClass.lt-sm="flex-grow"
    >
      <div
        class="mb-0.5 ml-4 flex flex-col"
        [matTooltip]="item.name"
        [matTooltipDisabled]="!item.name || item.name.length < 30"
        [matTooltipOptions]="{ delay: [500, 0] }"
      >
        <span class="font-semibold leading-snug">{{ translateViewType(item.type) }}</span>
        <span class="mat-hint leading-snug" *ngIf="item.name as desc">{{ desc | truncate: 30 }}</span>
      </div>
      <div
        class="mat-bd-5 flex h-12 flex-shrink-0 items-center rounded-lg px-4"
        [ngClass]="(isAMPM$ | async) ? 'lg:wflex-[0_0_270px]' : 'lg:wflex-[0_0_220px]'"
      >
        <div class="flex flex-col space-y-0.5">
          <div class="mat-hint text-sm leading-none" translate>timer.time.start</div>
          <div class="text-md font-semibold leading-none">
            {{ item.start | formatDate: 'time' : null : item.timeZone }}
          </div>
        </div>
        <div class="mx-3 mt-2 text-lg">-</div>
        <div class="flex flex-col space-y-0.5">
          <div class="mat-hint text-sm leading-none" translate>timer.time.end</div>
          <ng-container *ngIf="!item.meta.recording; else recordingText">
            <div class="text-md font-semibold leading-none">
              {{ item.end | formatDate: 'time' : null : item.timeZone }}
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="!item.meta.recording">
          <div class="mx-3">
            <mat-divider inset class="h-8 rotate-6" vertical></mat-divider>
          </div>
          <div class="flex flex-col space-y-0.5">
            <div class="mat-hint text-sm leading-none" translate>timer.time.duration</div>
            <div class="text-md font-semibold leading-none">
              {{ (item.end - item.start) / 1000 | parseMsAsDuration: false : true }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="flex space-x-2">
      <ng-container *ngIf="meta.errors?.length">
        <div
          class="flex h-10 w-10 items-center justify-center"
          matTooltip
          [matTooltipTemplate]="whErrorTooltip"
          [matTooltipTemplateContext]="{ errors: meta.errors }"
        >
          <mat-icon color="accent">warning</mat-icon>
        </div>
      </ng-container>
    </div>
    <div fxHide.lt-md class="flex space-x-2 opacity-0 group-hover:opacity-100">
      <button mat-icon-button *ngIf="editable" (click.stop)="editItem(view, item)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button *ngIf="deleteable" (click.stop)="deleteItem(view, item, confirmDeleteDialog)">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
    <div class="flex-auto" fxHide.lt-md></div>
  </div>
</ng-template>
<ng-template #scheduleView let-sched>
  <div
    class="mat-typography mat-bg-5-hover grid h-12 grid-cols-[1fr_28px] items-center gap-x-6 rounded-lg px-4"
    matTooltip
    [matTooltipTemplate]="scheduleTooltip"
    [matTooltipTemplateContext]="{ $implicit: sched }"
  >
    <div class="flex flex-col text-right text-sm">
      <span>{{ sched.usage | parseMsAsDuration }}</span>
      <span>{{ sched.cap | parseMsAsDuration }}</span>
    </div>
    <div class="mat-circle-card">
      <mat-progress-spinner
        [value]="sched.percent * 100 | clamp: 0 : 100"
        strokeWidth="3"
        [diameter]="20"
        [color]="sched.graphState"
        class="with-back-spinner"
      ></mat-progress-spinner>
    </div>
  </div>
</ng-template>
<ng-template #scheduleTooltip let-sched>
  <div class="mat-typography flex flex-col px-3 py-2.5">
    <div class="flex items-center space-x-2">
      <span>{{ sched.name || ('schedules.name' | translate) }}</span>
      <ng-container *ngIf="sched.percent >= 0">
        <span class="dot size-1.5"></span>
        <span>{{ sched.percent | percent }}</span>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #recordingText>
  <div class="text-warn flex items-center space-x-1 leading-none animate-pulse">
    <span class="dot mat-bg-5! size-1.5"></span>
    <span>Recording...</span>
  </div>
</ng-template>
<ng-template #confirmDeleteDialog let-ref="dialogRef" let-data="dialogData">
  <div matDialogTitle class="mat-dialog-title-base flex flex-col">
    <h4 class="my-0">{{ 'utils.confirmDeletion' | translate }}</h4>
  </div>
  <mat-dialog-content>
    <p>{{ 'comego.confirmDelete' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button (click)="ref.close(false)">{{ 'utils.cancel' | translate }}</button>
    <button mat-stroked-button color="warn" (click)="ref.close(true)">{{ 'utils.delete' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
<ng-template #whErrorTooltip let-errors="errors">
  <div class="flex flex-col space-y-2">
    <div class="flex items-start space-x-2" *ngFor="let werr of errors">
      <div class="dot mt-1.5 mat-size-1.5"></div>
      <div>{{ werr.message | translate: (werr.args | translateArgs) }}</div>
    </div>
  </div>
</ng-template>
